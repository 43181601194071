import React,{useRef} from 'react'
import getVideoId from "get-video-id"
import loadable from "@loadable/component";


const ReactPlayer = loadable(() => import("react-player"))
const { isIOS, isMobile } = loadable(() => import("react-device-detect"))

const YoutubePlayer = ({video_url}) => {

  const [isOpen, setIsOpen] = React.useState(false)
  const vidRef = useRef(null)
  let formattedVideoURL = ""

  const videoid = video_url && getVideoId(video_url)
  if (videoid) {
    formattedVideoURL = `https://www.youtube-nocookie.com/embed/${videoid.id}`
  }


  return (
    <div className="youtube-video-player">
        <ReactPlayer
          onEnded={() => {
            setIsOpen(false)
          }}
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          ref={vidRef}
          url={formattedVideoURL}
          modestbranding="1"
          controls
        //   autoplay
        //   muted={!!(isMobile && isIOS)}
        //   playsinline
        //   playing={isOpen}
          width="100%"
          height="100%"
        />
    </div>
  )
}

export default YoutubePlayer
import React, { useState,useEffect } from "react"
import {Modal, Container } from "react-bootstrap"
import "./NewsDetailsBanner.scss"
import PlayVideo from "../PlayVideo/PlayVideo"
import dateFormat from "dateformat"
import { Link } from "gatsby"
import { teamURL } from "../../lib/urls"
import getVideoId from "get-video-id"
import FormFields from "../../../static/forms/newsdetails.json"
import DefaultForm from "../../components/forms/default-form-layout"
import YoutubePlayer from "../YoutubePlayer/YoutubePlayer"
import useDeviceWidth from "../../hooks/useDeviceWidth"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const NewsDetailsBanner = ({ blogData }) => {
  const [isPlay, setPlay] = useState(false)
  const [videoForm, setVideoForm] = useState(false)
  const {
    banner_image,
    category,
    isFormOpen,
    blog_categories,
    content,
    published_by,
    imagetransforms,
    read_time,
    strapi_id,
    date,
    tile_image,
    title,
    topics,
    video_url,
    email_admin_subject,
    email_id,
    email_user_subject,
    form_title,
    mail_user_content
  } = blogData


  // const imagename = "blog.banner_image.details"

  const podCast = blog_categories?.some(item => item.slug === "podcasts")
  const imageCaption = banner_image?.caption;

  useEffect(()=>{
    if (window.instgrm){
    window.instgrm.Embeds.process();
    }
  },[])


const handleVideo = video_url => {
    setVideoForm(true)
}

const handleVideoFormClose = () => {
  setVideoForm(false)
}


const pageurl = typeof window !== "undefined" ? window.location.href : ""
const userText=mail_user_content?.data?.mail_user_content


const videoidColumn = video_url && getVideoId(video_url)
  let youTubeImageColumn=""
    if(videoidColumn?.id){
      youTubeImageColumn= `https://i.ytimg.com/vi/${videoidColumn?.id}/maxresdefault.jpg`
    }

const deviceWidth = useDeviceWidth()


  return (
    <div className="news-details-banner">
      <Container>
        <div className="new-details-banner-sections">
          <h1 className="news-heading">{title}</h1>
          {!podCast && published_by && (
            <div className="team-member">
              <div className="image-section">
                <img src={published_by?.image.url} alt={published_by?.name + " "+" - haus & haus"}/>
              </div>
              <div className="team-info">
                <div className="name-designation">
                  <Link to={`${teamURL}${published_by?.slug}/`}>
                    <p className="name">{published_by?.name}</p>
                  </Link>
                  <p className="designation">{published_by?.designation}</p>
                </div>
                <p className="date-time">
                  <span>{read_time}</span>{" "}
                  <span className="date">
                    {dateFormat(date, "mmm dd, yyyy")}
                  </span>
                </p>
              </div>
            </div>
          )}
          <div className="detail-banner-container">
            <div className="image-section">
              {video_url && isFormOpen && (
                <button
                  onClick={() => handleVideo(video_url)}
                  className="play-button"
                >
                  <i className="icon video-filled-icon" />
                </button>
              )}

              {!video_url?
              <figure>
                 {blogData?.mobile_image?.url && deviceWidth<768 ?
                <img src={blogData?.mobile_image?.url} alt={`${title}`} classNames="img-fluid"/>
                :
                <img src={banner_image?.url} alt={`${title}`} classNames="img-fluid"/>
                 }
                {!video_url && imageCaption &&
                <figcaption>{imageCaption}</figcaption>
                }
              </figure>
              :
              (video_url && isFormOpen) ?
              <img className="thumbnail" src={youTubeImageColumn} alt={`${title}`}/>
              :
              <YoutubePlayer video_url={video_url}/>
            }
            
            </div>
              <p className="description">
                <ContentModule Content={content?.data?.content} />
              </p>
          </div>
        </div>
      </Container>
      <Modal
        show={videoForm}
        // onHide={handleClose}
        backdrop="static"
        centered
        dialogClassName="modal-popup-form blog-modal-form"
      >
        <Modal.Body>
          <div className="popup-form-wrapper blog-form">
            <div className="close-modal" onClick={() => handleVideoFormClose()}>
              <i className="icon black-cancel-icon"></i>
            </div>
            <div>
              <DefaultForm
                fields={FormFields}
                formTitle={form_title}
                pageTitle={form_title}
                sourceUrl={pageurl}
                videoForm={videoForm}
                setPlay={setPlay}
                setVideoForm={setVideoForm}
                userContent={userText}
                to_email_id={email_id}
                emailSubUser={email_user_subject}
                emailSubAdmin={email_admin_subject}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <PlayVideo
        isOpen={isPlay}
        stopPlay={setPlay}
        videoId=""
        isCloseFunction={setPlay}
        videourl={video_url}
        htmlink={""}
      />
    </div>
  )
}

export default NewsDetailsBanner
import React from "react"
import { Container } from "react-bootstrap"
import "./PodCastListing.scss"
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const PodCastListing = ({ podcastsList }) => {
  return (
    <div className="postcast-wrapper">
      <Container>
        <div className="podcast-module">
          <h2 className="heading">{podcastsList?.heading}</h2>
          <div className="podcast-items">
            {podcastsList?.items?.map((item, index) => {
              return (
                <div className="podcast-card-list">
                  <div className="image-section">
                    <img src={item.image?.url} alt={item?.cta_title} />
                  </div>
                  <p className="podcast-name">
                    <ContentModule Content={item?.content?.data?.content} />
                  </p>
                  <a
                    href={item?.custom_link}
                    target="_blank"
                    className="podcast-link"
                  >
                    <span className="title">{item?.cta_title}</span>
                    <span className="icon-arrow">
                      <i className="icon black-top-arrow" />
                    </span>
                  </a>
                </div>
              )
            })}
          </div>
          <div className="horizontal-line"></div>
        </div>
      </Container>
    </div>
  )
}

export default PodCastListing

import { useEffect, useState } from "react"
const useDeviceWidth = () => {
  const currentWidth = typeof window !== "undefined" ? window.innerWidth : ""
  const [width, setWidth] = useState(currentWidth)
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  return width
}
export default useDeviceWidth
import React from 'react'
import { Container } from "react-bootstrap"
import "./NewsImageBlock.scss"
const {
    ImageModule,
  } = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const NewsImageBlock = ({imageData,blogData,isPreviewEnabled}) => {

    const { title, strapi_id,imagetransforms}= blogData
    let processedImages
    processedImages = imagetransforms?.banner_image_Transforms || JSON.stringify({})

    const imagename="blog.banner_image.details"

  return (
    <div className="image-block">
        <Container>
            <div className="image-section">
            {/* <ImageModule
              ImageSrc={imageData?.image}
              altText={`${title}`}
              imagetransforms={processedImages}
              renderer="pic-src"
              imagename={imagename}
              strapi_id={strapi_id}
              classNames="img-fluid"
            /> */}
            {(imageData?.image?.url || (isPreviewEnabled&&imageData?.image?.data?.attributes?.url) )&&
              <img src={imageData?.image?.url||imageData?.image?.data?.attributes?.url} alt={`${title}`} classNames="img-fluid" />
            }
            </div>
        </Container>
    </div>
  )
}

export default NewsImageBlock
import { Link } from "gatsby"
import React, { useState } from "react"
import { Container, Modal } from "react-bootstrap"
import FormFields from "../../../static/forms/news_subscription.json"
import f1 from "../../images/f1.png"
import SocialShareNew from "../SocialShare/SocialShareNew"
import DefaultForm from "../forms/default-form-layout"
import "./NewsStaticComponent.scss"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const {
  PreviewCTALink,
} = require("@starberry/gatsby-theme-utils/Modules/PreviewCTALinkModule")

const NewsStaticComponent = ({ blogData,isPreviewEnabled }) => {
  const [show, setShow] = useState(false)

  const news_detail_member = blogData?.news_detail_member

  const handleModal = data => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const formTitle = `Get news and insight straight to your inbox.`
  const pageurl = typeof window !== "undefined" ? window.location.href : ""


  const podCast = blogData?.category?.strapi_json_value?.includes("podcasts")

  return (
    <div className={`news-static-wrapper`}>
      <Container>
        <div className="static-sections_news">
          <div className="horizontal-line"> </div>
          <h3>Share</h3>
          <SocialShareNew iconClass="icon icon-share" shareText="Share:" news/>

          {/* {blogData?.news_detail_member && (
            <>
              <div className="horizontal-line"></div>
              <div className="person-news">
                <div className="image-section">
                  <img src={news_detail_member?.image.url} />
                </div>
                <div className="content-section">
                  <p className="name">{news_detail_member?.name}</p>
                  <p className="designation">
                    {news_detail_member?.designation}
                  </p>
                  <p className="description">
                    <ContentModule
                      Content={news_detail_member?.content?.data?.content}
                    />
                  </p>
                  <div className="link-text">
                    <Link to="/">
                    <span>Start a conversation with me</span>{" "}
                    <i className="icon black-top-arrow" />
                    </Link>
                   
                  </div>
                </div>
              </div>
            </>
          )} */}

          {!podCast && <div className="horizontal-line"> </div>}

          {!podCast && (
            <div className="card-icons-news">
              <div className="item-list">
                <img src={f1} />
                <div className="c_holder">
                  <p className="title">Subscribe to our monthly newsletter</p>
                  <div className="ctas">
                    <a href="javascript:void(0)" onClick={handleModal}>
                      <span className="link-text">Stay updated</span>{" "}
                      <span className="arrow">
                      <i className="icon black-top-arrow" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              {blogData?.card_list?.items?.map((item, index) => {
                return (
                  <div className="item-list item-m">
                    {(item?.image?.url || (isPreviewEnabled&&item?.image?.data?.attributes?.url))&&
                    <img src={item?.image?.url || item?.image?.data?.attributes?.url} alt={item?.cta_title} />
                    }
                    <div className="c_holder">
                      {(item?.content?.data?.content || (isPreviewEnabled&&item?.content))&&
                      <p className="title">
                        <ContentModule Content={item?.content?.data?.content || item?.content} />
                      </p>
                      }
                      <div className="ctas">
                        <span>
                          <PreviewCTALink
                            class=""
                            link={
                              item?.link?.data?.attributes ? item?.link?.data?.attributes:
                              (item?.link && !isPreviewEnabled)  
                                ? item?.link
                                : { external_link: item?.custom_link }
                            }
                            title={item.cta_title}
                            target_window={
                              item.link_type === "external"
                                ? "new_window"
                                : ""
                            }
                          />
                        </span>
                        <span className="arrow">
                          <i className="icon black-top-arrow" />
                        </span>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </Container>

      <Modal
        show={show}
        // onHide={handleClose}
        backdrop="static"
        centered
        dialogClassName="modal-popup-form news_subscribe"
      >
        <Modal.Body>
          <div className="popup-form-wrapper news_subscribe">
            <div className="close-modal" onClick={() => handleClose()}>
              <i className="icon black-cancel-icon"></i>
            </div>
            <div>
              <DefaultForm fields={FormFields} formTitle={formTitle} sourceUrl={pageurl}/>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default NewsStaticComponent
